import React from "react"
import styled from "styled-components"

const Footer = styled.div`
  grid-area: 3 / 1 / 4 / 2;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: 300 1em Museo;
  /*   background-color: blue;
 */
  padding: 3%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 3 / 1 / 4 / 3;
    padding: 0;
    margin-bottom: 0;
  }
`

export default () => {
  return (
    <Footer>
      © {new Date().getFullYear()}, Built with ❤️ by Raúl Berganza Gómez for
      Humans.
    </Footer>
  )
}
