import React, { useState, useRef } from "react"
import styled, { ThemeProvider } from "styled-components"

import { Link } from "gatsby"
import { useOnClickOutside } from "../hooks"
import { Burger, Menu } from "./burguerMenu"
import { theme } from "../theme"
import FocusLock from "react-focus-lock"

const Header = styled.div`
  border-bottom: 5px solid cyan;
  grid-area: 1 / 1 / 2 / 3;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    /*     background-color: red;
 */
    border-bottom: none;
  }
`

const MidContainer = styled.div`
  border-bottom: 5px magenta solid;
  height: 100%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    border-bottom: none;
  }
`
const InternalContainer = styled.div`
  display: grid;
  grid-template-columns: 50% auto;
  grid-template-rows: 100%;
  border-bottom: 5px solid yellow;
  height: 100%;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    /*     background-color: red;
 */
    grid-template-columns: 23% auto;
    grid-template-rows: 100%;
    border-bottom: none;
  }
`

const TitleContainer = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  height: 100%;
  /*   background-color: cyan;
 */
  padding-left: 15%;
  padding-right: 0%;
`

const NavBar = styled.div`
  display: flex;
  grid-area: 1 / 2 / 2 / 3;
  flex-flow: column;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: none;
  }
`

/* const NavItem = styled(Link)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  color: black;
  font: 300 1em Museo;
  @media only screen and (min-width: 768px) {
  }
`
 */

const SvgPort = styled.svg`
  height: 100%;
  display: flex;
`

const MainText = styled.text`
  font: 300 1.5em League;
`

export default () => {
  const [open, setOpen] = useState(false)
  const node = useRef()
  const menuId = "main-menu"

  useOnClickOutside(node, () => setOpen(false))

  return (
    <ThemeProvider theme={theme}>
      <Header>
        <MidContainer>
          <InternalContainer>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              <TitleContainer>
                <SvgPort viewBox="0 0 93 15">
                  <MainText x="0" y="15">
                    Raúl Berganza
                  </MainText>
                </SvgPort>
              </TitleContainer>
            </Link>
            <NavBar>
              <FocusLock disabled={!open} style={{ width: "100%" }}>
                <Burger
                  className="noSelect"
                  open={open}
                  setOpen={setOpen}
                  aria-controls={menuId}
                />
                <Menu open={open} setOpen={setOpen} id={menuId} />
              </FocusLock>
              {/*               <NavItem to={`/blog`}> Blog </NavItem>
              <NavItem to={`/reads`}> Reading </NavItem> */}
            </NavBar>
          </InternalContainer>
        </MidContainer>
      </Header>
    </ThemeProvider>
  )
}
