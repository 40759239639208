import styled from "styled-components"

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  background: white;
  transform: ${({ open }) => (open ? "translateX('0'%)" : "translateX(100%)")};
  height: 100vh;
  text-align: left;
  top: 11vh;
  left: 0;
  z-index: 2;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    min-width: 100%;
    min-height: 100vh;
  }

  a {
    font: 700 2em Museo;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: black;
    text-decoration: none;
    transition: color 0.3s linear;
    box-shadow: inset 0px -2px 0px 0px rgba(204, 204, 204, 1);

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`
