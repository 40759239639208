import React from "react"
import { graphql } from "gatsby"

import Sidebar from "../components/sidebar"
import Footer from "../components/footer"
import Header from "../components/header"
import { Helmet } from "react-helmet"

import Body from "../components/body_blog"

import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 11vh auto 9%;

  @media only screen and (min-width: 768px) {
    /* For everything bigger than 968px */
    grid-template-columns: 23% auto;
    grid-template-rows: 8vh auto 9%;
  }

  @media only screen and (min-width: 1000px) {
    /* For everything bigger than 968px */
    grid-template-columns: 23% auto;
    grid-template-rows: 11vh auto 9%;
  }

  @media only screen and (min-width: 1200px) {
    /* For everything bigger than 968px */
    grid-template-columns: 23% auto;
    grid-template-rows: 13vh auto 9%;
  }
`

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Container id="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Raúl Berganza - Pushing Computing Forward</title>
      </Helmet>
      <Header />
      <Sidebar />
      <Body posts={posts} />
      <Footer />
    </Container>
  )
}

export default BlogIndex

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
