import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby"
import SocialBar from "../components/socialbar"

const Sidebar = styled.div`
  display: none;
  /*   background-color: yellow;
 */
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2 / 1 / 3 / 2;
    display: grid;
    grid-template-columns: 15% auto 0%;
    grid-template-rows: 100%;
  }
`

const Container = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    display: flex;
    flex-flow: column nowrap;
    grid-area: 1 / 2 / 2 / 3;
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  font: 300 1.5em Museo;
`

const TextWrapperLast = styled(TextWrapper)`
  flex: 3;
  @media only screen and (min-width: 1400px) {
    /* For everything bigger than 768px */
    flex: 7;
  }
`

const ImgContainerLast = styled.div`
  flex: 1;
`

const Row = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`

export default () => {
  const data = useStaticQuery(graphql`
    query FaceQuery {
      img1: file(absolutePath: { regex: "/mug-raul-bk.png/" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img2: file(absolutePath: { regex: "/mug-raul-cyan.png/" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img3: file(absolutePath: { regex: "/mug-raul-yellow.png/" }) {
        childImageSharp {
          fluid(maxWidth: 100, maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      img4: file(absolutePath: { regex: "/mug-raul-magenta.png/" }) {
        childImageSharp {
          fluid(maxWidth: 50, maxHeight: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Sidebar>
      <Container>
        <div>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            <Image
              fluid={data.img1.childImageSharp.fluid}
              style={{
                border: "2px solid black",
              }}
            />
          </Link>
        </div>
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
            display: `flex`,
          }}
          to={`/blog`}
        >
          <Row>
            <TextWrapper style={{ flex: 1 }}>Blog</TextWrapper>
            <div style={{ flex: 1 }}>
              <Image
                fluid={data.img2.childImageSharp.fluid}
                style={{
                  border: "2px solid cyan",
                }}
              />
            </div>
          </Row>
        </Link>

        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/about`}
        >
          <Row>
            <TextWrapper style={{ flex: 3 }}>About me</TextWrapper>
            <div style={{ flex: 1 }}>
              <Image
                fluid={data.img3.childImageSharp.fluid}
                style={{
                  border: "2px solid yellow",
                }}
              />
            </div>
          </Row>
        </Link>

        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/reads`}
        >
          <Row>
            <TextWrapperLast>Reading</TextWrapperLast>
            <ImgContainerLast>
              <Image
                fluid={data.img4.childImageSharp.fluid}
                style={{
                  border: "2px solid magenta",
                }}
              />
            </ImgContainerLast>
          </Row>
        </Link>
        <div style={{ marginTop: "10%" }}>
          <SocialBar />
        </div>
      </Container>
    </Sidebar>
  )
}
