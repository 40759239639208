import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Body = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  /*   background-color: green;
 */
  display: flex;
  flex-flow: column nowrap;
  padding: 7%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
    grid-area: 2/ 2 / 3 / 3;
    padding-top: 0;
    overflow: scroll;
  }
`

const Title = styled.div`
  margin-top: 0;
  font: 700 1.5em Museo;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`
const Article = styled.article`
  margin-bottom: 4%;
  @media only screen and (min-width: 768px) {
    /* For everything bigger than 768px */
  }
`

const Date = styled.small`
  font: 300 0.85em Museo;
`
const Description = styled.p`
  font: 300 1.3em Museo;
`
/* const Header = styled.h1`
  margin-top: 0;
  font: 700 2.3em Museo;
  @media only screen and (min-width: 768px) {
  }
` */

export default ({ posts }) => {
  return (
    <Body>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <Article key={node.fields.slug}>
            <header>
              <Title>
                <Link
                  style={{
                    boxShadow: `none`,
                    textDecoration: `none`,
                    color: `inherit`,
                  }}
                  to={node.fields.slug}
                >
                  {title}
                </Link>
              </Title>
              <Date>{node.frontmatter.date}</Date>
            </header>
            <section>
              <Description
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </Article>
        )
      })}
    </Body>
  )
}
