import React from "react"
import { bool } from "prop-types"
import { StyledMenu } from "./Menu.styled"

const Menu = ({ open, ...props }) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <a href="/blog" tabIndex={tabIndex}>
        Blog
      </a>
      <a href="/about" tabIndex={tabIndex}>
        About me
      </a>
      <a href="/reads" tabIndex={tabIndex}>
        Reading
      </a>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu
